import Vue from 'vue'

// base library
import {
  Button,
  Card,
  Modal,
  Spin,
  Icon,
  notification
} from 'ant-design-vue'

Vue.use(Button)
Vue.use(Card)
Vue.use(Modal)
Vue.use(Spin)
Vue.use(Icon)


Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$notification = notification
import PageLoading from '@/components/PageLoading'
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

Vue.use(PageLoading)
