
/*
 * @description:
 * @param :
 * @return:
 */
// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'vant/lib/index.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import { VueAxios } from './utils/request'
import './utils/lazy_use'
// import QRcode from '@/components/QRcode'
import http from './utils/http'
Vue.config.productionTip = false

Vue.prototype.http = http
Vue.prototype.$store = store
// 二维码
// Vue.use(QRcode)
Vue.use(VueAxios)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
