import request from './request'

const http = {
  get (url, params) {
    const config = {
      method: 'get',
      url: url,
      params: params
    }
    return request(config)
  },
  post (url, params) {
    const config = {
      method: 'post',
      url: url,
      data: params
    }
    return request(config)
  }
}

export default http
