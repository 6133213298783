import Vue from 'vue'
import axios from 'axios'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },
  // baseURL: baseURL,
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // baseURL: '/api',
  // baseURL: 'https://openapi.cens.cn',
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
    }
  }
  Vue.prototype.$loading.hide()
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  if (config.requestBase === 'VUE_APP_API_BASE_URL_OSS') {
    config.baseURL = process.env.VUE_APP_API_BASE_URL_OSS
  } else if (config.requestBase === 'VUE_APP_API_BASE_URL_OSS_T') {
    config.baseURL = process.env.VUE_APP_API_BASE_URL_OSS_T
  }
  Vue.prototype.$loading.show()

  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  Vue.prototype.$loading.hide()
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
